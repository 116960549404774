import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found"/>
        <div className={"container"}>
            <h1>NON TROVATO</h1>
            <p>Pagina non trovata. Torna alla home</p>
        </div>
    </Layout>
)

export default NotFoundPage
